.figure-wrapper {
  position: relative;
  display: block;
  margin-bottom: 30px;

  .figure-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: $white;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: 0.3s;

    &:after {
      content: "";
      background-color: rgba(#494949,0.35);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 0.85;

      transition: 0.3s;
    }

    .date {
      font-size: 21px;
      transform: rotate(-90deg);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px 15px;
      z-index: -1;
    }

    .title {
      transition: 0.3s;
      backface-visibility: hidden;
    }

    .btn {
      margin-top: 30px;
    }

    &--blue {
      &:after {
        background-color: rgba($primary, 0.85);
      }
    }
  }
}

a.figure-wrapper:hover {
  .figure-overlay {
    box-shadow: 0 0 2rem rgba(0,0,0,.35);
    .title {
      transform: scale(1.035);
    }
    &:after {
      background-color: rgba($green, 0.85);
    }
    &--blue {
      &:after {
        background-color: rgba($primary, 0.9);
      }
    }
  }
}

figure.figure-progressive {
  background-color: $light;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding-bottom: 47.4%;
  margin: 0;

  &.figure-4x3 {
    padding-bottom: 75%;
  }
  &.figure-1920x468 {
    padding-bottom: 24.4%;
  }
  &.figure-square {
    padding-bottom: 100%;
  }

  img {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s linear;
    &.loaded {
      opacity: 1;
    }
    &.img-small {

      opacity: 0.85;
      &:after {
        content: "";
        background-color: rgba(73,73,73,0.85);
      }
    }
  }

  .img-small {
    //filter: blur(50px);
    transform: scale(1);
  }
}