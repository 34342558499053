.bottom-bar {
  @extend %font-family-lato-semibold;
  .footer-link {
    display: block;
  }
}
@include media-breakpoint-up(md) {
  .bottom-bar {
    text-align: center;
    .footer-link {
      display: inline-block;
      padding: 0 30px;
    }
  }
}
