.container-carousel {
  margin: 0 auto;

  .owl-stage {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
}

.slider {
  background-color: rgba(73,73,73,0.55);
  background-size: cover;
  padding: 3rem 0;
  .item {
    display: block;
    margin: 0 auto;
    max-width: 210px;
    text-align: center;
    &__title {
      @include fluid-type($min_width, $max_width, (28px/1.7), 28px, 1, 1.1);
      margin-top: 30px;
      color: $white;
      position: relative;
      transition: 0.3s;
    }

    &__tooltip {
      background-color: $secondary;
      @extend %font-family-lato-light;
      @include fluid-type($min_width, $max_width, 14px, 16px, 1.4, 1.4);
      color: $white;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transition: 0.3s;
      transform: translateY(10px);
      padding: 10px 10px;
      z-index: 9;
      width: 70%;
      margin: auto;
      @include media-breakpoint-up(md) {
        opacity: 0;
      }
    }

    .figure-wrapper {
      margin-bottom: 30px;
    }

    .figure-overlay {
      background-color: rgba($blue,0.85);
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s;
      &:after {
        display: none;
      }
      img {
        width: 50%;
        height: 100%;
      }
    }

    figure {
      border-radius: 50%;
    }
  }
  a:hover {
    text-decoration: none;
    .figure-overlay {
      opacity: 1;
    }
    .item__title {
      transform: translateY(-10px);
    }

    .item__tooltip {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@include media-breakpoint-up(sm) {
  .container-carousel {
    //max-width: 540px;
  }
  .slider {
    padding: 9rem 0;
  }
}

@include media-breakpoint-up(md) {
  .container-carousel {
    //max-width: 720px;
  }
}

@include media-breakpoint-up(lg) {
  .container-carousel {
    //max-width: 960px;
  }
}

@include media-breakpoint-up(xl) {
  .container-carousel {
    //max-width: 1140px;
  }
}

@include media-breakpoint-up(xxl) {
  .container-carousel {
    max-width: 1400px;
  }
}