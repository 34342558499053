.content-block {
  margin-bottom: 30px;
  text-align: center;
  &--title {
    @extend %font-family-lato-black;
    @extend %dlig;
    @include fluid-type($min_width, $max_width, (55px/1.7), 55px, 1, 1);
    line-height: 55px;
    color: $blue;
  }

}

.subtitle {
  @extend %font-family-questa-grande-italic;
  @include fluid-type($min_width, $max_width, (40px/1.7), 40px, 1, 1);
  text-transform: uppercase;
  color: $blue;
}