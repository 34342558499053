.news-item-wrapper {
  margin: 0;

  .white--shape:before {
    background-image: url("../images/green-arrow.svg");

    width: 24px;
    height: 12px;
  }

  a.news-item {
    position: relative;
    display: block;
    height: 100%;
    padding: $spacer;
    color: inherit;
    backface-visibility: hidden;

    header {
      .figure-container {
        position: relative;
        &:before {
          content: "";
          height: 3px;
          position: absolute;
          bottom: -1px;
          left: -1rem;
          background-color: $white;
          width: calc(100% + 2rem);
          display: block;
          z-index: 9;
        }
      }
      figure {
        margin: -1rem -1rem 1rem -1rem;

        img {
          transition: 0.3s;
          backface-visibility: hidden;
        }

        &.white--shape:after {
          background-color: rgba(73,73,73,0.35);
          background-size: 80% 14%;
          backface-visibility: hidden;
          height: 100%;
          width: 100%;
          left: 0;
          bottom: 0;
          transform: rotate(-180deg) scaleX(1);
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 30px rgba(0,0,0,.15);
      opacity: 1;

      @include transition(0.3s);
    }
  }

  a:hover {
    text-decoration: none;
    backface-visibility: hidden;
    header {
      figure {
        img {
          transform: scale(1.02);
        }
        &.white--shape:after {
          background-color: rgba($secondary,0.65);
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .news-item-wrapper {
    a.news-item {
      header {
        figure {
          &.white--shape {
            &-bottom {
              &:after {
                background-size: 80% 12%;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .news-item-wrapper {
    a.news-item {
      header {
        display: flex;
        figure {
          margin: -1rem 0 -1rem -1rem;
          width: 50%;
          &.white--shape {
            &-bottom {
              &:before {
                top: 50%;
                bottom: auto;
                right: -5px;
                left: auto;
                transform: translateY(-6px) rotate(-90deg) scale(0.8);
              }
              &:after {
                top: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                right: -2%;
                left: -17%;
                background-size: 80% 10%;
                transform: rotate(90deg) scaleX(1.6);
              }
            }
          }
        }

        span {
          margin-left: 1rem;
          width: 50%;
        }
      }
    }
  }
}