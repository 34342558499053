nav {
    ul.pagination {
        margin-bottom: 1.5rem;
    }
    li.page-item {
        margin: 0 0.25rem;
        a {
            line-height: 1.8;
            font-weight: 400;
            text-align: center;
            text-decoration: none;
            display: inline-block;
        }
        &.previous, &.next {
            margin: 0 1rem;
        }
        &.previous {
            transform: rotate(45deg);
        }
        &.next {
            transform: rotate(225deg);
        }
        a.page-link {
            height: 40px;
            width: 40px;
            border: 1px solid $secondary;
            border-radius: 50%!important;
            position: relative;
            vertical-align: middle;
            line-height: 40px;
        }
        &.disabled {
            a.page-link {
                .custom-arrow {
                    &:after {
                        background-color: $white;
                    }
                    &:before {
                        background-color: $gray-300;
                    }
                }
            }
        }
    }
}

//custom arrow
.custom-arrow {
    display: inline;

    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $white;
        content: "";
        width: 10px;
        height: 10px;
        transform: translate(-2px, -9px);
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $secondary;
        content: "";
        width: 10px;
        height: 10px;
        transform: translate(-4px, -7px);
    }
}

a:hover {
    .custom-arrow {
        &:after {
            background-color: $secondary;
        }
        &:before {
            background-color: $white;
        }
    }
}