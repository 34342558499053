// Navbar

$navbar-padding-y:                  ($spacer / 2) !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .8rem;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   ($font-size-base * $line-height-base + $nav-link-padding-y * 2);

//$navbar-toggler-padding-y:          .25rem;
//$navbar-toggler-padding-x:          .75rem;
//$navbar-toggler-font-size:          $font-size-lg;
//$navbar-toggler-border-radius:      $btn-border-radius;

$navbar-light-color:                $body-color;
$navbar-light-hover-color:          $green;
$navbar-light-active-color:         $green;
$navbar-light-disabled-color:       rgba($body-color, .3);
//$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-light-toggler-border-color: transparent;

// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-padding-y:                2rem;
$dropdown-bg:                       rgba($white, .75);
$dropdown-border-color:             rgba($body-color, .15);
$dropdown-border-radius:            0;
$dropdown-border-width:             0;
//$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175);

$dropdown-link-color:               $body-color;
$dropdown-link-hover-color:         $green;
$dropdown-link-hover-bg:            transparent;

$dropdown-link-active-color:        $green;
$dropdown-link-active-bg:           transparent;

$dropdown-link-disabled-color:      rgba($body-color, .3);

//$dropdown-item-padding-y:         .25rem;
$dropdown-item-padding-x:           2.5rem;

//$dropdown-header-color:           $gray-600;