footer {
  padding: 3rem 0;
  @extend %font-family-lato-light;
  color: $white;
  text-transform: uppercase;

  h3 {
    margin-bottom: 30px;
    text-transform: none;
  }

  ul {
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 0.25rem;
    }
  }
  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}