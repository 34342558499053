@function palette($palette, $tone: 'base') {
        @return map-get(map-get($palettes, $palette), $tone);
}
@function rem-calc($size) { $remSize: $size / 16; @return #{$remSize}rem; }

@mixin scale($ratio) {
        -webkit-transform: scale($ratio);
        -ms-transform: scale($ratio); // IE9 only
        -o-transform: scale($ratio);
        transform: scale($ratio);
}

//responsive font-size
@function strip-unit($value) {
        @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size, $min-line-height, $max-line-height) {
        $u1: unit($min-vw);
        $u2: unit($max-vw);
        $u3: unit($min-font-size);
        $u4: unit($max-font-size);
        $min-line-height2: $min-line-height * $min-font-size;
        $max-line-height2: $max-line-height * $max-font-size;


        @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
                & {

                        font-size: $min-font-size;
                        line-height: $min-line-height2;
                        @media screen and (min-width: $min-vw) {
                                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                                line-height: calc(#{$min-line-height2} + #{strip-unit($max-line-height2 - $min-line-height2)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                        }

                        @media screen and (min-width: $max-vw) {
                                font-size: $max-font-size;
                        }
                }
        }
}

$min_width: 320px;
$max_width: 1920px;