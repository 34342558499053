.gallery {
    @include clearfix;
    margin: 0 -18px;

    a {
        display: inline-block;
        float: left;
        position: relative;
        padding: 18px;
        width: 100%;
        text-decoration: none;
        color: $body-color;

        figure {
            background-color: $green;
            transition: 0.3s;

            img {
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
        }
        @extend .renf-icons;
        @extend .renf-icons-magnifying-glass;

        &:before {
            text-rendering: optimizeLegibility;

            position: absolute;
            top: -15px;
            left: 0;
            height: 100%;
            width: 100%;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
            transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

            opacity: 0;
            z-index: 1;
        }

        &:hover {
            figure {
                box-shadow: 0 0 2rem rgba(0,0,0,0.35);
                img {
                    opacity: 0.2;
                }
            }
            &:before {
                font-size: 65px;
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
        }
    }
    .gallery-item {
        &--title {
            @extend %font-family-lato-heavy;
            @include fluid-type($min_width, $max_width, (24px/1.7), 24px, 1, 1.2);
            margin: 10px 0 5px;
        }
        &--subtitle {
            @extend %font-family-lato-light;
            @include fluid-type($min_width, $max_width, (24px/1.7), 24px, 1, 1.2);
            strong {
                @extend %font-family-lato-heavy;
                color: $green;
            }
        }
    }

    &.one-image {
        a {
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                width: 33.333%;
            }
        }
    }
    &.two-image {
        a {
            width: 50%;

            @include media-breakpoint-up(md) {
                width: 33.333%;
            }
        }
    }
    &.three-image {
        a {
            width: 50%;

            @include media-breakpoint-up(md) {
                width: 33.333%;
            }
        }
    }
    &.four-image {
        a {
            width: 50%;

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.more {
        a {
            width: 50%;

            @include media-breakpoint-up(md) {
                width: 33.333%;
            }

            @include media-breakpoint-up(lg) {
                width: 33.333%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .gallery {
        .gallery-item {
            &--title {
                margin: 14px 0 5px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .gallery {
        .gallery-item {
            &--title {
                display: inline-block;
                margin: 14px 0;
            }
            &--subtitle {
                display: inline-block;
                margin: 14px 0;
                float: right;
            }
        }
    }
}
