.author {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
   .post-meta {
    .post-date {
      @extend %font-family-questa-grande-regular;
      font-size: 21px;
    }
    .bottom--line:after {
      margin: 20px 0 10px 0;
    }
  }
  &-offset {
    margin-top: -2rem;
  }
}

@include media-breakpoint-up(md) {
  .author {
    &-offset {
      margin-top: -4rem;
    }
  }
}