%font-family-lato-regular {
  font-family: lato, sans-serif;
  font-weight: 400;
  font-style: normal;
}
%font-family-lato-italic {
  font-family: lato, sans-serif;
  font-weight: 400;
  font-style: italic;
}
%font-family-lato-black {
  font-family: lato,sans-serif;
  font-weight: 900;
  font-style: normal;
}
%font-family-lato-light {
  font-family: lato,sans-serif;
  font-weight: 300;
  font-style: normal;
}
%font-family-lato-semibold {
  font-family: lato,sans-serif;
  font-weight: 600;
  font-style: normal;
}
%font-family-lato-heavy {
  font-family: lato,sans-serif;
  font-weight: 800;
  font-style: normal;
}
%font-family-questa-grande-regular {
  font-family: questa-grande, serif;
  font-weight: 400;
  font-style: normal;
}
%font-family-questa-grande-italic {
  font-family: questa-grande, serif;
  font-weight: 400;
  font-style: italic;
}
%dlig {
  font-variant-ligatures: discretionary-ligatures;
  -moz-font-feature-settings: "dlig";
  -webkit-font-feature-settings: "dlig";
  font-feature-settings: "dlig";
}

body {
  @extend %font-family-lato-light;
  @include fluid-type($min_width, $max_width, 14px, 17px, 1.4, 1.6);
  color: $body-color;
}

p {
  margin-bottom: 1.6rem;
}
strong {
  font-weight: 600;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  @extend %dlig;
  + .subtitle {
    margin-bottom: 0;
  }
}

h1,.h1 {
  @extend %font-family-lato-black;
  margin-bottom: 30px;
  @include fluid-type($min_width, $max_width, (55px/1.7), 55px, 1, 1);
  color: $blue;
}

h2,.h2 {
  @extend %font-family-lato-heavy;
  @include fluid-type($min_width, $max_width, (28px/1.7), 28px, 1, 1.2);
}
h3,.h3 {
  @extend %font-family-lato-heavy;
  @include fluid-type($min_width, $max_width, (24px/1.7), 24px, 1, 1.7);
}

h4,.h4 {
  @extend %font-family-lato-heavy;
  @include fluid-type($min_width, $max_width, (20px/1.7), 20px, 1, 1.7);
}

h5,h6,.h5,.h6 {
  @extend %font-family-lato-heavy;
  @include fluid-type($min_width, $max_width, (17px/1.7), 17px, 1, 1.7);
}

.btn {
  @extend %font-family-questa-grande-italic;
  @include fluid-type($min_width, $max_width, (20px/1.7), 20px, 1, 1.7);
  @extend %dlig;
  &.btn-lg {
    @include fluid-type($min_width, $max_width, (23px/1.7), 23px, 1, 1.7);
  }
}

.secondary-font {
  @extend %font-family-questa-grande-italic;
  @extend %dlig;
  @include fluid-type($min_width, $max_width, (35px/1.7), 35px, 1, 1);
}

.date {
  @extend %font-family-questa-grande-regular;
  @extend %dlig;
  @include fluid-type($min_width, $max_width, (21px/1.7), 21px, 1, 1.1);
}

html {
  //font-size: $font-size-sm;
}

@include media-breakpoint-up(sm) {
  html {
    //font-size: $font-size-base;
  }
}

@include media-breakpoint-up(md) {
  html {
    //font-size: $font-size-base;
  }
}

@include media-breakpoint-up(xl) {
  html {
    //font-size: $font-size-lg;
  }
}