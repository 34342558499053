.submenu {
  padding-left: 40px;
  border-left: 2px solid rgba($body-color,0.07);
  ul {
    padding: 0;
    list-style: none;
    li {
      padding: .25rem 0;
      a {
        position: relative;
        color: $body-color;
        font-weight: 600;
        text-decoration: none;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          display: none;
        }
      }
    }

    li.active {
      a {
        color: $green;
        font-weight: 800;
        &:after {
          background-color: $green;
          display: block;
        }
      }
    }
    li:hover {
      a {
        color: $green;
      }
    }
  }
}