.share {
  position: relative;
  padding-left: 40px;
  border-left: 2px solid rgba($body-color,0.07);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;
      padding: 0;
      text-align: center;

      &:before {
        display: none;
      }

      a {
        color: $white;
        &:after {
          display: none;
        }

        i {
          background-color: $green;
          display: inline-block;
          margin-right: 4px;

          width: 46px;
          height: 46px;

          border-radius: 23px;
          line-height: 40px;
          text-align: center;
          border: 3px solid $white;
          transition: 0.3s;
        }
        &:hover {

          i {
            color: $white;
            border: 3px solid $green;
          }
        }
      }
    }
  }
}