.breadcrumb {

  li {
    @extend .breadcrumb-item;
    font-style: italic;
    a {
      color: inherit;
    }

    &.last {
      @extend %font-family-lato-semibold;
      a {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: $body-color;
          height: 1px;
          width: 100%;
        }
      }
    }
    &:hover {
      a {
        color: $green;
      }
      &.last {
        a:after {
          background-color: $green;
        }
      }
    }
  }
}