.product-item-wrapper {
  .product-item {
    margin-bottom: 40px;
  }
}
@include media-breakpoint-down(md) {
  .product-item-wrapper {
    .product-item {
      overflow: hidden;
      height: 180px;
      .figure-overlay .date {
        width: 180px;
      }
    }
  }
}