//custom settings bootstrap components
@import "navigation";
@import "breadcrumb";
@import "buttons";
@import "form";
@import "pagination";

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           40px;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1280px
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
            0: 0,
            1: ($spacer * 1),
            2: ($spacer * 2),
            3: $spacer * 3,
            4: ($spacer * 4),
            5: ($spacer * 5),
            6: ($spacer * 6)
    ),
    $spacers
);
