a.cta {
  background-color: transparent;
  .cta__title {
    margin-top: 30px;
  }
  .cta__icon {
    margin-top: 10px;
    img {
      width: 50px;
    }
  }
  &.white--shape-left {
    &:before {
      width: 24px;
      height: 12px;
    }
  }
  .figure-overlay:after {
    opacity: 1!important;
  }
  &:after {
    //display: none;
  }
}