$palettes: (

        blue: (
                base: #003876
        ),
        red: (
                base: #d9534f
        ),
        gray: (
                light: #f8f9fa,
                dark: #343a40
        )

);
$blue:          #003876;
$green:         #00a263;
$primary:       #003876;
$secondary:     $green;

$light:         #f8f8f8;
$dark:          $blue;

$white:    #fff;
$black:    #000;

$body-color:    #474747;

$link-color:                $green;
$link-decoration:           none;
$link-hover-color:          $green;
$link-hover-decoration:     none;


$theme-colors: () !default;
$theme-colors: map-merge((
        "dunique-green":    palette(blue, base),
        "white":    $white
), $theme-colors);


// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $black;
$yiq-text-light:            $white;