.navbar {
  position: relative;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  max-width: 1600px;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .navbar-text {
    display: inline-block;
    text-transform: none;
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: $body-color;
    font-weight: 800;
    text-align: left;
    order: 2;

    span {
      display: none;
    }
    a {
      color: $body-color;
      font-weight: 800;
      text-align: left;
    }
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    font-size: 0;
    line-height: inherit;
    text-align: center;
    z-index: 9;
    order: 1;
    img {
      max-width: 60px;
    }
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    order: 3;
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-block;

    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, (18px/1.25), 18px, 1, 1);
      color: #474747;
      font-weight: 400;
      line-height: 96px;
      text-transform: uppercase;
      text-decoration: none;
      transition: 0.3s;

      &:not(.btn){
        padding: 0 0.8rem;
        color: $body-color;
      }
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        position: relative;
        display: inline-block;
        transform: rotate(-45deg);
        margin-left: 15px;
        margin-bottom: 5px;
        vertical-align: middle;

        &:before {
          background-color: $body-color;
          transition: 0.3s;
        }

        &:after {
          border: none;
        }
      }
    }

    //mega submenu
    > .submenu {
      background-color: rgba($white,0.75);
      position: absolute;
      top: 100%;
      left: -1.7rem;
      padding: 2rem 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      box-shadow: 0 1rem 1rem rgba(0,0,0,.275);
      border: none;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          min-width: 260px;

          @include fluid-type($min_width, $max_width, (17px/1.25), 17px, 1, 1);

          > li {
            position: relative;
            padding: .75rem 2.5rem;
            break-inside: avoid;

            a {
              position: relative;
              text-transform: none;
              text-decoration: none;
              transition: 0.3s;
            }

            .custom-arrow, .submenu {
              display: none;
            }

            &.highlight {
              margin-top: 1.25rem;
              @include fluid-type($min_width, $max_width, (13px/1.25), 13px, 1, 1);
              a {
                color: $secondary;
                font-weight: 900;
                &:after {
                  background-color: $secondary;
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 1px;
                  width: 100%;
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      .custom-arrow {
        &:before {
          background-color: $secondary;
        }
      }
      > a:not(.btn) {
        color: $secondary;
        font-weight: 700;
      }
    }

    //open submenu
    &:hover {
      .custom-arrow {
        &:before {
          background-color: $secondary;
        }
      }
      > a:not(.btn) {
        color: $secondary;
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

#nav-secundary {
  display: none;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 1rem;

  > li {
    display: inline-block;
    > a {
      padding: 0 2rem;
      display: inline-block;
      font-weight: 600;
      line-height: 40px;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(sm) {

  ul.nav-desktop {
    li {
      > a {
        &:not(.btn){

        }
      }
    }
  }

  #nav-secundary {
    display: flex;
    > li {
      > a:not(.btn) {
        padding: 0 2rem;
        line-height: 64px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    .nav {
      justify-content: flex-end;
      flex: 1;
    }
    .navbar-brand {
      img {
        max-width: 180px;
        margin: 0 0 -90px 0;
      }
    }

    .navbar-text {
      flex: 1;
      order: 1;
      span {
        display: inline-block;
      }
    }
  }
  body {
    .nav-container {
      &:not(.sticky) {
        position: absolute;
        background-color: transparent;
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    position: fixed;
    top: 0;
    left: 0;

    &.sticky {
      box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);

      .nav-topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 0.5rem 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }

  ul#nav-secundary {
    display: none;
  }
}

@include media-breakpoint-up(xl) {

  ul.nav-desktop {

    > li {
      padding: .5rem 1rem;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.125rem 0;
        }
      }
    }
  }
}
