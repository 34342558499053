body {
  margin: 0 auto;
  min-width: $min_width;
  max-width: $max_width;
}

.bottom--line {
  position: relative;
  &:after {
    content: "";
    border-top: 2px solid $body-color;
    width: 35px;
    z-index: -1;
    left: 0;
    display: block;
    margin: 15px 0;
  }
}

.content-page {
  a {
    position: relative;

    &:not(.cta) {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $green;
        height: 1px;
        width: 100%;
      }
    }
    &:not(.btn) {
      font-weight: 800;
      &:hover {
        color: darken($green, 5%);
      }
    }
  }
}

.header-image {
  background-color: rgba(73, 73, 73, 1);
  figure {
    opacity: 0.70;
  }
}

.white--shape {
  position: relative;
  padding-top: 9rem;

  &:before {
    background-image: url("../images/blue-arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";

    position: absolute;
    top: 11px;

    width: 24px;
    height: 12px;

    transform: translateX(-12px);
    left: 50%;
    z-index: 9;
  }

  &:after {
    background-image: url("../images/white-shape.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    content: "";

    position: absolute;
    top: -1px;
    width: 80%;
    height: 100px;
    background-size: 100%;

    left: 10%;
    z-index: 1;

    transition: 0.3s;
  }

  &-bottom {
    padding-top: 0;
    &:before {
      top: auto;
      bottom: 1px;
    }
    &-header:before {
      display: none;
    }
    &-header:after {
      background-size: 870px!important;
      top: auto;
      width: 80%;
      height: 100px;
      left: 10%;
      transform: rotate(0deg) scaleX(0.6);
      display: none;
      background-color: transparent!important;
    }

    &:not(&-header):after {
      top: auto;
      bottom: -1px;
      transform: rotate(-180deg) scaleX(0.9);
    }
  }

  &-left {
    padding-top: 0;
    &:before {
      top: 50%;
      bottom: auto;
      right: auto;
      left: 0;
      transform: translateX(-12px) rotate(-90deg);
      //display: none;
    }
    &:after {
      background-image: url("../images/white-shape-small.svg");
      top: 10%;
      bottom: 0;
      width: 100px;
      height: 80%;
      right: auto;
      left: -1px;
      background-position-x: right;
      background-size: contain;
      transform: rotate(-180deg);
      //display: none;
    }
  }

  &-right {
    padding-top: 0;
    &:before {
      top: 50%;
      bottom: auto;
      right: 0;
      left: auto;
      transform: translateX(-12px) rotate(-90deg);
      display: none;
    }
    &:after {
      background-image: url("../images/white-shape-small.svg");
      top: 10%;
      bottom: -1px;
      width: 100px;
      height: 80%;
      right: 0;
      left: auto;
      background-position-x: right;
      background-size: contain;
      display: none;
    }
  }
}

blockquote {
  max-width: 70%;
  margin: 2rem auto 1rem;
  text-align: center;

  p:first-of-type {
    font-size: 22px;
    font-style: italic;
    font-weight: 400;

    &:before {
      content: "“";
      display: inline-block;
      font-size: 86px;
      transform: translateY(20px);
      font-weight: bold;
      margin: 0 0.5rem;
      line-height: 20px;
      font-family: questa-grande,serif;
      color: #00a263;
    }
  }
}

@include media-breakpoint-up(sm) {
  .bottom--line {
    &:after {
      margin: 35px 0;
    }
  }
  .text-center-block {
    max-width: 66%;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(lg) {
  .white--shape {
    &:before {
      width: 32px;
      height: 15px;
      transform: translateX(-16px);
    }
    &:after {
      background-size: 540px 55px;
    }
    &-left {
      &:before {
        transform: translateY(-8px) rotate(-90deg);
        display: block;
      }
      &:after {
        background-size: contain;
        display: block;
      }
    }
    &-right {
      &:before {
        transform: translateY(-15px) rotate(-90deg);
        display: block;
      }
      &:after {
        background-size: contain;
        display: block;
      }
    }
    &-bottom {
      &-header:before {
        display: block;
      }
      &-header:after {
        display: block;
      }
    }

  }
}